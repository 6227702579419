.backgroudimage {
  background: url(./Images/aaroha-logo-no-background.png) no-repeat center center;
  background-size: cover;
  position: relative;
}

.flowbackgroudimage {
  background: url(./Images/flow-logo.png) no-repeat center center;
  background-size: 85% auto;;
  position: relative;
}

.flowwithoutbackgroudimage {
  background: url(./Images/flow-logo-no-background.png) no-repeat right center;
  background-size: contain;
  position: relative;
  background-origin: content-box;
}

.momentumbackgroudimage {
  background: url(./Images/momentum-logo.png) no-repeat center center;
  background-size: 75% auto;
  position: relative;
}

.momentumwithoutbackgroudimage {
  background: url(./Images/momentum-logo-no-background.png) no-repeat right center;
  background-size: 40% auto;
  position: relative;
  background-origin: content-box;
}

.preciselybackgroudimage {
  background: url(./Images/precisely-logo.png) no-repeat center center;
  background-size: 75% auto;
  position: relative;
}

.preciselywithoutbackgroudimage {
  background: url(./Images/precisely-logo-no-background.png) no-repeat right center;
  background-size: 40% auto;
  position: relative;
  background-origin: content-box;
}

.claritybackgroudimage {
  background: url(./Images/clarity-logo-no-background.png) no-repeat center center;
  background-size: 55% auto;
  position: relative;
}
.claritywithoutbackgroudimage {
  background: url(./Images/clarity-logo-no-background.png) no-repeat right center;
  background-size: 20% auto;
  position: relative;
  background-origin: content-box;
}